import { Injectable } from '@angular/core';
import { RestApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { HttpClientService } from '../http-client.service';

export interface IFileUrl {
  url: string;
  expiresIn: number;
}

export interface ICreatedBy {
  entityType: string;
  entityId: string;
  entityName: string;
}

export interface IAssets {
  s3FileName: string;
  originalFileName: string;
  fileUrl: IFileUrl;
  photoUrl: IFileUrl;
  contentType: string;
  fileType: string;
}

export interface IPreScreening {
  basicInformation: {
    brandName: string;
    brandManagerOrPOC: {
      name: string;
      contactNumber: {
        primary: string;
        secondary: string;
      };
    },
    email: string;
    webSiteLink: string;
  },
  registeredOfficeAddress: {
    address: string;
    district: string;
    nearestLandmark: string;
    state: string;
    pincode: number;
  };
  fssaiCertificateDetails: {
    fssaiNumber: number;
    fssaiExpiryDate: string;
    certificateImage: IAssets;
  },
  termsAndConditionAgreement: {
    isAccepted: boolean;
  };
  status: {
    updatedAt: string;
    createdAt: string;
  };
}

export interface ITaxAndBankDetails {
  gstIn: string;
  panCard: string;
  udyogAadhaar: string;
  bankDetails: {
    accountHolderName: string;
    accountNumber: number;
    confirmAccountNumber: number;
    ifscCode: string;
    cancellationChequeOrBankPassBookImage: IAssets;
  },
  status: {
    isDraft: boolean;
    isSubmitted: boolean;
    updatedAt: string;
    createdAt: string;
    submittedAt: string;
  },
  isPanVerified: boolean;
  isGstInVerified: boolean;
  isUdyogAadhaarVerified: boolean;
}

export interface IVerification {
  documents: {
    productionUnit: Array<IAssets>;
    productsAndCatalogue: Array<IAssets>;
  },
  status: {
    updatedAt: string;
    createdAt: string;
  };
}

export interface IBrandSetup {
  brandProfile: {
    brandName: string;
    brandAssets: Array<IAssets>;
    badges: Array<string>;
    certificateAndLabTestReportAssets: Array<IAssets>;
  };
  status: {
    currentState: string;
  };
}

export interface IStoreFrontBrandDetails {
  _id: string;
  storeFrontBrandId: string;
  preScreening: IPreScreening;
  brandSetup: IBrandSetup;
  taxAndBankDetails: ITaxAndBankDetails;
  verification: IVerification;
  uniqueLink: string;
}

export interface IBrandApprovalLog {
  _id: string;
  storeFrontBrandApprovalId: string;
  storeFrontBrandId: string;
  approvalStatus: string;
  action: string;
  remarks: string;
  createdAt: string;
  updatedAt: string;
  createdBy: ICreatedBy;
  __v: number;
}

export interface IStoreFrontProfileApproval {
  _id: string;
  storeFrontBrandId: string;
  approvalStatus: string;
  brandApprovalLogs: Array<IBrandApprovalLog>;
  brandSetup: IBrandSetup;
  storeFrontBrandApprovalId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IStoreFrontProfileApprovalList {
  totalCount: number;
  totalPages: number;
  skip: number;
  limit: number;
  results: Array<IStoreFrontProfileApproval>;
}

export interface IStoreFrontProductApproval {
  _id: string;
  storefrontProductId: string;
  storefrontProductApprovalId: string;
  storeFrontBrandId: string;
  name: string;
  approvalStatus: string;
  createdAt: string;
  updatedAt: string;
  brandName: string;
}

export interface IStoreFrontProductApprovalList {
  productApprovals: Array<IStoreFrontProductApproval>;
  productApprovalsCount: number;
}

export interface IStoreFrontProfileFilter {
  search: string;
  approvalStatus: string;
}

export interface IBadge {
  _id: string;
  badgeName: string;
  badgeImage: IAssets;
  badgeId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

@Injectable()
export class BrandProfileService extends RestApiService {
  protected adminServiceBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  protected sellerPipelineApiUrl = environment.config['SELLER_PIPELINE_SERVICE_URL'];

  private servicePath = `${this.sellerPipelineApiUrl}`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  // Brand profile approval related API's
  async fetchStoreFrontBrandDetailsById(storeFrontBrandId: string): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.servicePath}/storefront-portal/${storeFrontBrandId}`;
    return this.httpGet(apiPath);
  }

  async getStoreFrontBadge(): Promise<{ data: Array<IBadge>; }> {
    const apiPath = `${this.servicePath}/storefront-badge`;
    return this.httpGet(apiPath);
  }

  async fetchAllStoreFrontProfileApprovalList(queryParams: Partial<any>): Promise<{ data: IStoreFrontProfileApprovalList; }> {
    const searchParams = new URLSearchParams();
    const filter: IStoreFrontProfileFilter = {} as IStoreFrontProfileFilter;
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'all') {
        filter.approvalStatus = queryParams['approvalStatus'];
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('search' in queryParams) {
        filter.search = queryParams['search'];
      } else {
        searchParams.delete('search');
      }
    }
    if (Object.keys(filter).length) {
      searchParams.set('filter', JSON.stringify(filter));
    }
    const apiPath = `${this.servicePath}/storefront-portal-approval?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchStoreFrontApprovalDetailsById(storeFrontBrandApprovalId: string)
    : Promise<{ data: { brandApproval: IStoreFrontProfileApproval; }; }> {
    const apiPath = `${this.servicePath}/storefront-portal-approval/${storeFrontBrandApprovalId}`;
    return this.httpGet(apiPath);
  }

  async updateBrandSetupProfileById(storeFrontBrandId: string, payload: { brandSetup: IBrandSetup; }): Promise<{ data: IStoreFrontBrandDetails; }> {
    const apiPath = `${this.servicePath}/storefront-portal/${storeFrontBrandId}/brand-setup`;
    return this.httpPut(apiPath, payload);
  }

  async updateStoreFrontProfileApprovalStatus(storeFrontBrandApprovalId: string, payload: { approvalStatus: string, remarks?: string; })
    : Promise<{ data: IStoreFrontProfileApproval; }> {
    const apiPath = `${this.servicePath}/storefront-portal-approval/${storeFrontBrandApprovalId}/status`;
    return this.httpPatch(apiPath, payload);
  }

  // Brand product approval related API's
  async fetchAllStoreFrontProductApprovalList(queryParams: Partial<any>): Promise<{ data: IStoreFrontProductApprovalList; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['storeFrontBrandId']) {
        searchParams.set('storeFrontBrandId', String(queryParams['storeFrontBrandId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'ALL') {
        searchParams.set('approvalStatus', String(queryParams['approvalStatus']));
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('isExistingProduct' in queryParams) {
        searchParams.set('isExistingProduct', String(queryParams['isExistingProduct']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/storefront-product-approvals/list?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchStoreFrontProductApprovalLogs(storefrontProductApprovalId: string) {
    const apiPath = `${this.servicePath}/storefrontProductApprovalId/logs/${storefrontProductApprovalId}`;
    return this.httpGet(apiPath);
  }
}
